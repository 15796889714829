import React, { useState } from "react"
import styled from "styled-components"
import Link from "next/link"
import { colors } from "../../styles/colors"
import { useGlobalState } from "../../context/GlobalContextProvider"

const Header = () => {
  const state = useGlobalState()
  // const pool = state?.currentStoreDetails?.pool ?? 'prod' //Hide Cart from stores that aren't part of our checkout testing.
  return (
    <Wrapper>
      <Nav>
        <Link href="/">
          <LogoImg src={"/unqueuewordmarklogo.png"} />
        </Link>
        <Cart>
          <Link href="/cart">
            <a>Cart ({state.shoppingCart.length})</a>
          </Link>
        </Cart>
      </Nav>
    </Wrapper>
  )
}

export default Header

const Wrapper = styled.div`
  background: ${colors.base};
  position: sticky;
  top: 0;
  z-index: 99;
`
const Nav = styled.nav`
  max-width: 1200px;
  margin: 0 auto;
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const LogoImg = styled.img`
  height: 21px;
  cursor: pointer;
`

const Cart = styled.div`
  display: block;
  align-self: center;
  justify-self: flex-end;
  margin-right: 20px;
  font-size: 20px;
  color: #fff;

  > a {
    color: #fff;
  }
`
